<template>
    <div id="Cart">
        <SubHeader title="결제하기" :link="''" :bookmark="false" :alert="false"></SubHeader>
        <div class="mt-2 last-border-0 pb-6">
            <div class="px-3 pb-2">
                <div class="border border-gray-relative-700"></div>
                <h6 class="py-2">구매자 정보</h6>
                <div class="cutting_line border-gray-relative-500 pb-3"></div>
                <ul class="small">
                    <li class="flex-start-center mb-2">
                        <span class="w-px-100 text-gray-relative-600">이름</span>
                        <span>{{ name }}</span>
                    </li>
                    <li class="flex-start-center mb-2">
                        <span class="w-px-100 text-gray-relative-600">아이디</span>
                        <span>{{ email }}</span>
                    </li>
                    <li class="flex-start-center mb-2">
                        <span class="w-px-100 text-gray-relative-600">휴대폰번호</span>
                        <span>{{ phone }}</span>
                    </li>
                </ul>
            </div>
            <div class="px-3 pb-2">
                <div class="border border-gray-relative-700"></div>
                <h6 class="py-2">결제 정보</h6>
                <div class="cutting_line border-gray-relative-500 pb-3"></div>
                <ul class="small">
                    <li class="flex-start-center mb-2">
                        <span class="w-px-100 text-gray-relative-600">총 결제금액</span>
                        <span><b>{{ totalValue.toLocaleString() }}</b>원</span>
                    </li>
                    <li class="flex-start-center mb-2">
                        <span class="w-px-100 text-gray-relative-600">결제방법</span>
                        <div class="round_checkbox">
                            <div class="me-3">
                                <input type="radio" v-model="payment_type" id="payment_method_0" value="C">
                                <label for="payment_method_0">
                                    <div class="chk_icon me-1"></div>
                                    <span class="conditions text-truncate lh-1">신용/체크카드</span>
                                </label>
                            </div>
                            <div>
                                <input type="radio" v-model="payment_type" id="payment_method_1" value="B">
                                <label for="payment_method_1">
                                    <div class="chk_icon me-1"></div>
                                    <span class="conditions text-truncate lh-1">무통장입금</span>
                                </label>
                            </div>
                        </div>
                    </li>
                </ul>

                <div v-if="payment_type=='B'">
                    <div class="p-3 border mt-3">
                        <b class="fs-px-16">입금 계좌정보 안내</b>
                        <div class="text-gray-relative-700 fs-px-14">
                            <p class="mb-2 lh-sm mt-2">
                                -구매 전 입금금액과 입금계좌 정보를 확인해주시기 바랍니다.
                            </p>
                            <p class="mb-2 lh-sm">
                                -오후 2시 입금건까지 당일 처리되며, 이후 입금건은 다음날 영업시간에 처리됩니다.
                            </p>
                            <p class="mb-2 lh-sm">-입금자명과 회원명이 일치해야 합니다.</p>
                        </div>
                        <div class="p-3 my-3 border text-center border-gray-relative-200">
                            <div class="d-flex flex-column">
                                <img :src="bank_image" alt="" class="bank_logo" />
                            </div>
                            <div>
                                <h5 class="fw-bold mb-1">{{ bank_account }}</h5>
                                <p>예금주 : {{ bank_holder }}</p>
                            </div>
                        </div>
                        <p class="text-gray-relative-700 fs-px-14">
                            입금 정보는 마이페이지의 거래내역에서 확인하실 수 있습니다.
                        </p>
                    </div>
                </div>
                <div v-if="payment_type=='C'">
                    <div class="p-3 border mt-3">
                        <b class="fs-px-16">카드 정보 안내</b>
                        <div class="text-gray-relative-700 fs-px-14">
                            <p class="mb-2 lh-sm mt-2">
                                -구매전 카드 결제 정보를 확인해주세요.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="mt-4 border-top border-gray-relative-200 pt-3">
                    <ul>
                        <li v-for="(item,index) in terms_list" :key="index" @click="$refs.TermsPopup.pp_on(index)">
                            <div class="flex-between-center">
                                <div class="fs-px-13 text-gray-relative-600">{{item.title}}</div>
                                <button class="btn btn-transparent fs-px-13 text-primary border-bopttom lh-sm ">보기</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="w-100 position-fixed bottom-0 start-0 px-3 py-2 bg-body">
                <div class="btn btn-main w-100" @click="BuyCoupon()" >결제하기</div>
            </div>
        </div>
        <TermsPopup ref="TermsPopup" />

        <div class="mb-4" style="display: none">
            <form name="frmConfirm" ref="form" accept-charset="EUC-KR">
                <input name="TYPE" value="P"/>
                <input name="PAYMETHOD" value="CARD"/>
                <input name="CERTTYPE" value="01"/>
                <input name="CPID" v-model="CP_ID"/>
                <input name="ORDERNO" v-model="order_no"/>
                <input name="PRODUCTTYPE" value="2"/>
                <input name="AMOUNT" v-model="totalValue"/>
                <input name="PRODUCTNAME" v-model="product_name"/>
                <input name="PRODUCTCODE" value="A001"/>
                <input type="text" name="EMAIL" size="100" maxlength="100" v-model="user_email">
                <input type="text" name="USERNAME" size="30" maxlength="30" v-model="user_name">
                <input type="text" name="SET_LOGO" size="10" v-model="logo">
                <input type=text name=HOMEURL v-model="HOME_URL">
                <input type=text name=USERID v-model="USER_ID">
                <input type=text name=CLOSEURL v-model="CLOSE_URL">
                <input type=text name=FAILURL  v-model="FAIL_URL">
                <input type=text name=APPURL  v-model="APP_URL">
            </form>
        </div>
    </div>
</template>

<script>
const CryptoJS = require('crypto-js');
import SubHeader from '@/components/common/SubHeader.vue';
import TermsPopup from '@/components/popup/Terms.vue'

export default {
    components: {
        SubHeader,TermsPopup
    },
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            totalValue: '',
            PAYMETHOD: "CARD",
            payment_card: '',
            payment_installment: '',
            count : '',
            payment_type : 'C',
            bank_image: 'https://golfandyacht.s3.ap-northeast-2.amazonaws.com/1692675167330수협은행 로고.png',
            bank_account: '1010-2488-5581',
            bank_holder: '주식회사 골프앤요트',

            terms_list: [
                {
                    idx: 1,
                    title: "이용자 약관 정보",
                },
                {
                    idx: 2,
                    title: "개인정보 처리 방침",
                },
            ],
            product_name : '',
            CP_ID : process.env.VUE_APP_CPID,
            HOME_URL : process.env.VUE_APP_HOME_URL,
            CLOSE_URL : process.env.VUE_APP_CLOSE_URL,
            FAIL_URL : process.env.VUE_APP_FAIL_URL,
            APP_URL : process.env.VUE_APP_APP_URL,
            WEB_POST_URL : process.env.VUE_APP_WEB_PG_URL,
            POST_URL : process.env.VUE_APP_PG_URL,
            user_name : process.env.VUE_APP_USER_NAME,
            user_email : '',
            USER_ID : process.env.VUE_APP_USER_ID,

            order_no :'',
            logo : '',
            PAY_TYPE : 'P',
            product_type : 1,
            product_code : 'PP'
        };
    },
    computed:{
        
    },
    mounted(){
        this.$store.dispatch('RESETORDER')
        this.order_no = this.CreateOrderNo()+''+this.RandomInt(1000,9999);
        this.logo = process.env.VUE_APP_LOGO;

        this.$EventBus.$emit('HideNav');
        this.GetCartInfo();
        this.GetBankInfo();
    },
    destroyed() {
        this.$EventBus.$emit('ShowNav');
    },
    methods: {
        GetBankInfo(){
            
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/front/shop/GetBankInfo', { req }).then((res) => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        const e_body = res.data.body;
                        const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                        const d_res = bytes.toString(CryptoJS.enc.Utf8);
                        const body = JSON.parse(d_res);

                        this.bank_account = body.info.account;
                        this.bank_image = body.info.image;
                        this.bank_holder = body.info.holder;
                        
                    } else if (res.data.code == '9999') {
                        this.$store.dispatch('SETLOGOUT').then(() => {
                            this.$router.push({ path: '/signin' });
                        });
                    }
                }
            });
        },
        GetCartInfo(){

            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/front/shop/GetCartInfo', { req }).then((res) => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        const e_body = res.data.body;
                        const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                        const d_res = bytes.toString(CryptoJS.enc.Utf8);
                        const body = JSON.parse(d_res);
                        
                        const name = body.user_info.name;
                        const email = body.user_info.email;
                        const price = body.info.price;
                        const count = body.info.count;
                        const phone = body.user_info.phone;
                        
                        this.user_email = body.user_info.email;
                        // this.product_name = body.info.product_name;
                        this.product_name = "골프앤요트";
                        
                        this.name = name;
                        this.email = email;
                        this.phone = phone;
                        this.totalValue = price;
                        this.count = count;

                        
                    } else if (res.data.code == '9999') {
                        this.$store.dispatch('SETLOGOUT').then(() => {
                            this.$router.push({ path: '/signin' });
                        });
                    }
                }
            });
        },
        BuyCoupon() {
            
            
            const total_price = this.totalValue;
            const payment_type = this.payment_type;
            const count = this.count;
            const bank_account = this.bank_account;
            const bank_holder = this.bank_holder;
            const bank_code = this.bank_code;
            const bank_image = this.bank_image
            const order_no = this.CreateOrderNo()+''+this.RandomInt(1000,9999);

            if(payment_type =='C'){
                
                const body = {total_price,count,payment_type,bank_account,bank_code,bank_holder,bank_image,order_no};
                const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

                this.$http.post('/front/shop/BuyTicketCartCard', { req }).then((res) => {
                    if (res.status == 200) {
                        if (res.data.code == '200') {
                            this.GotoPaymentWeb();
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                });


            }else{

                const body = {total_price,count,payment_type,bank_account,bank_code,bank_holder,bank_image};
                const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

                this.$http.post('/front/shop/BuyTicketCart', { req }).then((res) => {
                    if (res.status == 200) {
                        if (res.data.code == '200') {
                            this.$alert('상품권 구매 신청하였습니다.').then(() => {
                                this.$router.push('/mypage/history');
                            });
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                });
            }
            
        },
        GotoPaymentWeb(){
            const order = this.order_no;
            this.$store.dispatch('SETORDER', order);
            var pf = this.$refs.form;
            var fileName = "https://api.kiwoompay.co.kr/pay/link";
            pf.action = fileName;
            pf.method = "post";
            pf.submit();
        },
        RandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min);
        },
        CreateOrderNo(){
            var d = new Date();
            var month = d.getMonth() + 1;
            var date = d.getDate();
            var hour = d.getHours();
            var minute = d.getMinutes();
            var second = d.getSeconds();


            month = (month < 10 ? "0" : "") + month;
            date = (date < 10 ? "0" : "") + date;
            hour = (hour < 10 ? "0" : "") + hour;
            minute = (minute < 10 ? "0" : "") + minute;
            second = (second < 10 ? "0" : "") + second;

            var s = d.getFullYear() + month + date + hour + minute + second;

            return s;

        }

    },
    
};


</script>
