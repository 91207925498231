<template>
  <div class="mh-vh-100 flex-center-center flex-column text-center">
    <h5 class="text-center fw-bold mb-3">
      결제 완료하였습니다.
    </h5>

    <div class="p-3">
      <img src="@/assets/img/firecracker.png" alt="" height="100" class="mb-4" />
      <!-- <img src="@/assets/img/gift.png" alt="" height="70" style="filter:hue-rotate(165deg);transform:rotate(45deg)" class="position-absolute"> -->
    </div>

    <div class="position-absolute bottom-0 start-0 w-100 p-3">
      <button class="btn btn-main thick w-100 border-0 mb-3">
        <a :href="link" class="d-block"> 내역 확인하기 </a>
      </button>
    </div>
  </div>
</template>

<script>
const CryptoJS = require('crypto-js');

export default {
  data() {
    return {
      sender: this.$route.query.sender,
      pp: false,
      off: false,
      timeout_id: null,
      link: '/mypage/history',
      order : this.$store.state.order
    };
  },
  components: {},
  props: {
    item_info: {
      type: Object,
    },
  },
  mounted() {
    this.$EventBus.$emit('HideNav');
    alert(this.order);

  },
  destroyed() {
    this.$EventBus.$emit('ShowNav');
  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
* {
  word-break: keep-all;
}
</style>
